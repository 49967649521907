
/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Iqbal Wahyudi Pratama - iqbalwahyudip.com
==============================================================================================*/
@import "sass-lib";

body {  
    color: #333;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    position: relative;
    overflow-x: hidden;
    -webkit-text-size-adjust:100%;
}
figure{
    margin: 0; padding: 0;
    img{vertical-align: middle;}
}
img{ border:0; max-width:100%; height:auto; }
p {
    margin: 1px 0 15px;
    line-height: 24px;
}
input, textarea, select {
    font-size: 16px;
    border: none;
    border: 1px solid #D8D8D8;
    padding: 10px;
    margin: 0;
    color: #666;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-family: $barlow;
}
input[type=checkbox], input[type=radio] {
    border-top-style: none;
    border-right-style: none;
    border-bottom-style: none;
    border-left-style: none;
}
input[type="number"]::-webkit-outer-spin-button, 
input[type="number"]::-webkit-inner-spin-button  {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    opacity: 0;
}
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
h1,h3{line-height: 1.1;}
h4,h5,h6{line-height: 1.1;}
h1 { font-size: 57px; }
h2 {
    font-size: 48px; font-weight: 300;line-height: 51px;
    b,strong{font-weight: 500;}
}
h3 { font-size: 40px; }
h4 { font-size: 29px; font-weight: 300;} 
h5 { font-size: 24px; } 
h6 { font-size: 20px; }
/* structure 
----------------------------------------------------------------------------------------------*/
section { position: relative; }
.wrapper {width: 770px;margin: 0 auto; @include afterboth;}
.wrap-lg {width: 1600px;margin: 0 auto; @include afterboth;}
.wrap-md {width: 770px;margin: 0 auto; @include afterboth;}
.wrap-sm {width: 770px;margin: 0 auto; @include afterboth;}
.bg-blue{background: $blue;color: $white;}
.bg-grey{background: #f2f2f2;}
.after_clear{@include afterboth;}
.a-center{text-align: center;}
.a-left{text-align: left;}
.a-right{text-align: right;}
.trigger{position: absolute; top: 0; left: 0; right: 0; bottom: 0; margin: auto; height: .5px; }
.link-red{
    text-transform: uppercase;display: inline-block;font-weight: 700;color: $red;
    &:hover{color: $red;text-decoration: underline;}

    &.nocaps{text-transform: none;}
}

.link-download{
    display: inline-block;padding: 20px 40px 20px 55px;color:$darkblue;background: $white;@include borderRadius(5px);font-size: 13px;@include boxSizing(border-box);width: 470px;position: relative;max-width: 100%;@include transition(.2s all ease-out);line-height: 16px;border: 1px solid $darkblue;
    &:before{content: "";position: absolute;top: 0;left: 0;bottom: 0;width: 55px;height:23px;background: url('../images/material/ic_pdf_2.png')no-repeat center bottom;margin: auto 0;}
    &:after{content: "";position: absolute;top: 0;right: 0;bottom: 0;width: 35px;height:20px;background: url('../images/material/ic_download_2.png')no-repeat center bottom;margin: auto 0;}
    span{max-height: 32px;overflow: hidden;display: block;}
    &:hover{
        background: $darkblue;color: $white;
        &:before{background-position:center top;}
        &:after{background-position:center top;}
    }
}

.btn-box{
    display: inline-block;min-width: 160px;padding: 0 20px;min-height: 40px;line-height: 40px;@include boxSizing(border-box);border: 1px solid transparent;background: transparent;
    color: $darkblue;font-weight: 700;text-transform: uppercase;font-size: 13px;@include transition(.2s all ease-out);text-align: center;cursor: pointer;

    &.btn-md{font-size: 18px;height: 55px;line-height: 55px;padding: 0 45px;}

    &.btn-ln-blue{
        border: 1px solid $darkblue;color: $darkblue;
        &:hover{background:$darkblue;color: $white;}
    }
    &.btn-blue{
        border: 1px solid $darkblue;color: $white;background: $darkblue;
        &:hover{background:$white;color: $darkblue;}
    }
    &.btn-ln-red{
        border: 1px solid $red;color: $red;
        &:hover{background:$red;color: $white;}
    }
    &.btn-red{
        border: 1px solid $red;color: $white;background: $red;
        &:hover{background:$white;color: $red;}
    }
    &.btn-ln-white{
        border: 1px solid $white;color: $white;
        &:hover{background:$white;color: $blue;}
    }
    &.btn-ln-orange{
        border: 1px solid $orange;color: $orange;
        &:hover{background:$orange;color: $white;}
    }
    &.btn-orange{
        border: 1px solid $orange;color: $white;background: $orange;
        &:hover{background:$white;color: $orange;}
    }

    &.btn-pdf{
        border: 1px solid $red;color: $white;background: $red;padding-left: 58px;font-size: 16px;font-weight: 500;position: relative;height: 50px;line-height: 50px;
        &:before{content: "";width: 23px;height: 24px;background: url('../images/material/ic_pdf.png')no-repeat top center;position: absolute;top: 50%;left: 18px;margin-top: -11px;}
        &:hover{
            background:$white;color: $red;
            &:before{background-position:bottom center;}
        }
    }
    &.btn-vid{
        padding-left: 37px;position: relative;
        &:before{content: "";width: 8px;height: 11px;background: url('../images/material/ic_play.png')no-repeat top center;position: absolute;top: 50%;left: 18px;margin-top: -5px;}
        &:hover{
            &:before{background-position:bottom center;}
        }
    }
}

.icwp{
    display: inline-block; width: 18px; height: 18px; background-repeat: no-repeat; background-position: top center;
    &:hover{background-position: bottom center;}

    &.ic_up{width: 19px;height: 25px;background-image: url('../images/material/ic_up.png');}
    &.ic_down{width: 19px;height: 25px;background-image: url('../images/material/ic_down.png');}
    &.ic_dotred{width: 17px;height: 17px;background-image: url('../images/material/dot-red.png');vertical-align: middle;}
    &.ic_dotblue{width: 17px;height: 17px;background-image: url('../images/material/dot-blue.png');vertical-align: middle;}

    &.ic_docs{width: 23px;height: 23px;background-image: url('../images/material/ic_docs_blue.png');}
    &.ic_download{
        width: 23px;height: 23px;background-image: url('../images/material/ic_download.png');
        &:hover{background-image: url('../images/material/ic_download_black.png');}
    }
    &.ic_tw_grey{width: 23px;height: 23px;background-image: url('../images/material/ic_tw_grey.png');}
    &.ic_fb_grey{width: 23px;height: 23px;background-image: url('../images/material/ic_fb_grey.png');}
    &.ic_in_grey{width: 23px;height: 23px;background-image: url('../images/material/ic_in_grey.png');}
}

/* row list styles 
-----------------------------------------*/
.row_list{
    margin-left: -15px; margin-right: -15px; @include boxSizing(border-box);
    *{@include boxSizing(border-box);}
    &:after{content: ""; display: table; clear: both;}
    .col{
        width: 100%; padding: 0 15px; float: left;
        &.fr{float: right;}
        &.no-pad{padding: 0;}
        &.marg28{margin-bottom: 28px;}
        &.nomarg{margin-bottom: 0;}
        &.col_9{width: 90%;}
        &.col_85{width: 85%;}
        &.col_8{width: 80%;}
        &.col_75{width: 75%;}
        &.col_7{width: 70%;}
        &.col_67{width: 67.5%;}
        &.col_66{width: 66.66666666666666%;}
        &.col_65{width: 65%;}
        &.col_6{width: 60%;}
        &.col_55{width: 55%;}
        &.col_5{width: 50%;}
        &.col_45{width: 45%;}
        &.col_4{width: 40%;}
        &.col_37{width: 37%;}
        &.col_35{width: 35%;}
        &.col_33{width: 33.3333333333333%;}
        &.col_3{width: 30%;}
        &.col_25{width: 25%;}
        &.col_2{width: 20%;}
        &.col_16{width: 16.66666666666666%;}
        &.col_15{width: 15%;}
        &.col_125{width: 12.5%;}
        &.col_1{width: 10%;}
    }
    &.center{
        text-align: center;
        .col{float: none; display: inline-block;}
    }
    &.listfull{
        margin-left: 0; margin-right: 0;
        .col{padding: 0;}
    }
    &.listp5{
        margin-left: -5px; margin-right: -5px;
        .col{padding: 0 5px;}
    }
    &.listp10{
        margin-left: -10px; margin-right: -10px;
        .col{padding: 0 10px;}
    }
}
/* end row list styles 
-----------------------------------------*/


/* header
----------------------------------------------------------------------------------------------*/
header{
    position: fixed;top: 0;left: 0;right: 0;z-index: 100;background: $white;padding: 0 215px;height: 100px;border-bottom: 1px solid #d7d7d7;@include transition(.2s all ease-out);
    .logo{
        display: block;float: left;padding-top: 24px;@include transition(.2s all ease-out); position: relative; z-index: 2;
        img{@include transition(.2s all ease-out);}
    }
    .nav-head{
        position: absolute; top: 0;left: 0; right: 0; text-align: center; z-index: 1;
        > li{
            display: inline-block;margin: 42px 23px 0;@include transition(.2s all ease-out); position: static;
            a{
                text-transform: uppercase;font-weight: 500;color:#666666;display: block;@include transition(.2s all ease-out);
                position: relative; font-size: 13px; letter-spacing: 1px;
                &:before{ content:''; width: 5px; height: 5px; position: absolute; left: -15px; top: 5px;background: #dfe0eb; }
            }
            >a{padding-bottom: 43px;@include transition(.2s all ease-out);}
            /*&:before{content:"";position: absolute;bottom: 0;left: 0;right: 0;height: 2px;background: $orange;opacity: 0;}*/
            &:first-child{margin-left: 0;}
            &:last-child{margin-right: 0;}

            &.active{
                a{color: $darkblue;font-weight: 700;}
            }
            &:hover{
                /*&:before{opacity:1;};*/ 
                > a{
                    color: $darkblue;font-weight: 700;
                    &:before{ background:#e83626; }
                }
            }
            &.parent{
                > ul{
                    position: absolute;top: 100%;background: #fff;left: 0;right: 0;overflow: hidden;@include transition(0.2s all ease-out);height: 0;
                    &:before{ content:''; background:#d8d8d8; width: 100%; position: absolute; left: 0; top: 0; right: 0; height: 1px; }
                    
                    >li{
                        padding: 0 15px; display: inline-block;
                        a{
                            text-align: center;color: #666666;line-height: 20px; font-weight: 500; font-size: 13px;

                            &:hover{color:#1c2674;}
                            /*&:before{ display: none; }*/
                        }
                        > ul{
                            height: 0; overflow: hidden;@include transition(0.2s all ease-out); visibility: hidden; position: relative;
                        }
                        &:first-child{padding-top: 20px;}
                        &:last-child{padding-bottom: 15px;}
                        &.active{
                            a{color: $red;}
                        }&:hover{
                            > a{
                                color: $darkblue;
                                &:before{ background:#e83626; }
                            }
                        }
                    }
                }

                &:hover{
                    > ul{
                        height: 60px; visibility: visible;
                    }
                }

                /*&.nav-business{
                    > ul{
                        left: -20px;right: -20px;
                    }
                }
                &.nav-news{
                    > ul{
                        left: -20px;right: -20px;
                    }
                }
                &.nav-invest{
                    > ul{
                        left: -30px;right: -30px;
                    }
                }*/
            }
        }
    }
    .nav-right{
        float: right;margin-top: 38px;@include transition(.2s all ease-out); position: relative; z-index: 1;
        .lang-head{float: left;}
        .search-head{float: left;}
    }

    &.float{
        height: 80px;
        .logo{
            padding-top: 20px;
            img{max-width: 150px;}
        }
        .nav-head{
            > li{
                margin-top: 32px;
                > a{padding-bottom: 32px;}
                &.parent{
                    > ul{
                        &:before{ content:''; background:#d8d8d8; width: 100%; position: absolute; left: 0; top: 0; right: 0; height: 1px; }
                        &:after{ content:''; background:#d8d8d8; width: 100%; position: absolute; left: 0; bottom:0; right: 0; height: 1px; }
                    
                    }
                }
            }
        }
        .nav-right{margin-top: 27px;}
    }
}
.lang-head{
    position: relative;
    > span{
        padding: 0 45px 0 20px;border-left: 1px solid #eaeaea;border-right: 1px solid #eaeaea;color: #999999;position: relative;
        display: block;cursor: pointer;height: 24px;line-height: 24px; 
        &:before{content: "";position: absolute;top: 50%;right: 25px;border-left: 3px solid transparent;border-right: 3px solid transparent;border-top: 3px solid $red;margin-top: -2px;}
    }
    .drop-lang{
        position: absolute;top: 100%;left: 0;right: 0;padding: 10px 0;background: $white;display: none;

        a{
            display: block;padding: 5px 20px;font-weight: 500;color: #999999;
            &:hover,&.active{color:$darkblue;}
            &:last-child{padding-bottom: 0;}
        }
    }
}
.search-head{
    position: relative;
    > span{width: 40px;height: 24px;background: url('../images/material/ic_search.png') no-repeat right center;display: block;cursor: pointer;}
    .drop-search{
        position: absolute;top: -6px;right: -12px;display: none;
        input[type=text]{
            float: left;display: block;font-size: 14px;color: #333;font-weight: 500;height: 35px;padding: 0 10px;width: 250px;@include boxSizing(border-box);
            @include andplaceholder{font-style: italic;color: #a5a5a5;}
        }
        input[type=submit]{position: absolute;top: 50%;right: 10px;border: none;padding: 0;width: 20px;height: 20px;background: url(../images/material/ic_search_grey.png) no-repeat center;margin-top: -10px;}
    }
}

/* middle
----------------------------------------------------------------------------------------------*/
.content{margin-top: 100px;}
.box-sliderhome{
    position: relative;
    .sliderhome{z-index: 1;}
    .arr-slide{
        .arr-inslide{
            a{
                display: block;width: 50px;height: 50px;@include borderRadius(100%);border: 1px solid $white;float: left;margin-left: 15px;@include transition(.2s background-color ease-out);position: absolute;top:50%;z-index: 2;margin-top: -25px;
                &:first-child{margin-left:0;}
                &.prev{
                    background: url('../images/material/prev-arrow.png') no-repeat center;left: 5%;
                    &:hover{background-image: url('../images/material/prev-arrow-blue.png');background-color: $white;}
                }
                &.next{
                    background: url('../images/material/next-arrow.png') no-repeat center;right: 5%;
                    &:hover{background-image: url('../images/material/next-arrow-blue.png');background-color: $white;}
                }
            }
        }
    }
    .ket{
        font-size: 13px; color: #fff; font-style: italic; position: absolute; left: 10%; bottom: 8%; z-index: 3;
        opacity: 0.8; padding-left: 10px;
        &:before{ content:''; position: absolute;background: #c8362b; width:5px; height: 5px; left: 0; top: 0; bottom: 0; margin: auto 0;  }
    }
}
.sliderhome{
    .list{
        position: relative;display: block;
        figure{
            position: relative;
            &:before{content:"";position: absolute;top: 0;left: 0;bottom: 0;right: 0;background: url('../images/material/fg-sliderhome.png') no-repeat center bottom;background-size: 100%;z-index: 1;}
            img{ width: 100%; }
        }
        .play-vid{position: absolute;top: 0;left: 0;right: 0;bottom: 0;width: 99px;height: 70px;background: url('../images/material/btn-play-red-lg.png')no-repeat center;margin: auto;z-index: 10;}
        .box-video-slide{
            position: absolute;top: 0;left: 0;right: 0;bottom: 0;width: 100%;height: 100%;display: flex;flex-direction: column;justify-content: center;align-items: center;
            .ytb{width: 100%;height: 100%;}
        }
        .centerimg{position: absolute;top: 50%;left: 50%;@include transform(translate(-50%,-50%));margin-top: -100px;z-index: 4;}
        .text-banner{
            position: absolute;top: 50%;left: 50%;@include transform(translate(-50%,-50%));margin-top: 0px;z-index: 4;color: $white;text-align: center;width: 730px;
            h2{font-weight: 700;margin-bottom: 25px;font-size: 72px;line-height: 80px;color: $white;}
            p{font-size: 23px;line-height: 33px;color: $white;}
        }
        .desc{  
            position: absolute;bottom: 25px;left: 0;right: 0;width: 1170px;margin: 0 auto;color: $white;padding-right: 300px;@include boxSizing(border-box);z-index: 2;
            &:before{content: "";position: absolute;top: 0;bottom: 0;left: 135px;width: 1px;background: rgba(229,221,210,.2);}
            .lf{
                float: left;width: 100px;position: relative;@include boxSizing(border-box);text-transform: uppercase;padding-left: 35px;font-size: 18px;font-weight: 500;
                img{position: absolute;top: 0;left: 0;}
            }
            .rg{
                float: right;width: 700px;
                p{line-height: 20px;margin-bottom: 10px;}
                span{font-size: 12px;color: #999999;display: block;}
            }
        }
        &.vid{
            &:before{display:none;}
            figure{position: relative;z-index: 2;}
        }
    }
}

.partner{
    .partner-top{
        position: relative;min-height: 700px;
        @include afterboth;
        figure{width: 49.29%;position: absolute;bottom: 0;min-height: 637px;}
        .right{
            float: none;width: 1170px;margin: 0 auto;position: relative;z-index: 2;display: block;
            h2{
                margin-bottom: 30px;line-height: 51px;
                b,strong{color: $blue;}
            }
            p{margin-bottom: 50px;line-height: 30px;}
            .linered{display: inline-block;margin-left: -40px;width: 100px;height: 2px;background: $red;clear: both;}
            .inright{padding:160px 0 0 49%;}
            .btn-box{margin-right: 20px;}
        }
    }
}
.home{
    .wrapper{ width: 1170px; }
    h2{ 
        font-size: 34px;  font-weight: 500; color: #0a2336; line-height: 42px;
        b{ color: #4675cd; }
    }
    .tittle{
        text-align: center; width: 770px; margin: 0 auto 30px;  
    }
    &.qts{
        padding: 50px 0;       
        .wrapper{ position: relative; width: 830px;}
        &:before{
           content:''; background: url(../images/material/mapindo.png)no-repeat center; width: 942px; height: 337px; position: absolute; top: 0; right: 0; left: 0; 
            margin:auto; bottom: 0;
        }
        p{ text-align: center; font-size: 16px; color: #333333; font-weight: 400; line-height: 20px;}
        
    }
    &.solution{
        padding: 35px 0 60px; @include boxSizing(border-box);
        .wrapper{ position: relative;  }
        &:before{ 
            content:''; background: url(../images/material/bg-finan.png)no-repeat center; width:100%; height: 440px;
             position: absolute; top: 0; right: 0; bottom: 0; left: 0; background-size: 100%;
        }
        .tittle-finan{
            width: 830px; text-align: center; margin:0 auto 30px;
        }
        h2{ margin-bottom: 30px; }
        p{ font-weight: 400; }
        .box-solution{
            margin: 0 -30px;
            &:after{ content:''; display: block; clear: both;  }
            .list-sol{
                float: left; width: 33.333%; padding: 0 30px; @include boxSizing(border-box);
                text-align: center; position: relative; padding-bottom: 25px; cursor: pointer;
                &:before{
                    content:''; width: 30px; height: 2px; background: #e73726; position: absolute; bottom: 0; right: 0; left: 0; margin: auto;
                }
                figure{
                    img{ @include transition(.2s all ease-out); }
                }
                &:hover{
                    figure{
                        img{ @include transform(scale(0.9)); @include transition(.2s all ease-out); }
                    }
                }
            }
            figure{ margin: 0 auto; display: block; width: 100%; }
            h4{ font-size: 20px; color: #111; font-weight: 500; margin: 10px 0 15px; text-transform: uppercase;}
            small{ font-size: 13px; color: #333;  font-weight: 400; line-height: 16px;max-height: 64px;overflow: hidden;display: block;}
        }
    }
    &.newsupdate{
        padding: 30px 0; @include boxSizing(border-box);
        h3{ font-size: 24px; margin-bottom: 20px; line-height: 30px;}
        p{ font-size: 13px; line-height: 18px;  }
        .row{
            margin-bottom: 25px;
            .right{
                a{ 
                    font-size:13px; color: #e83626; position: relative;  padding-left: 15px; font-weight: bold; text-transform: uppercase;
                    &:before{ content:''; width: 5px; height:5px; background:#1c2674; margin:auto 0; position: absolute; left: 0; top: 0; bottom: 0;  }
                }
            }
        }
        .wrap-newsupdate{
            .box-highlt{
                border:1px solid #eaeaea; margin-bottom: 60px;
                &:after{ content:''; clear: both; display: block; }
                p{ height: 128px; overflow: hidden; font-weight: 400;color: #333;}
                h6{font-size: 14px;margin-bottom: 10px;color: #666;}
                h3{max-height: 90px;overflow: hidden;color: #333;}
                figure{
                    float: left; width: 50%; margin: -1px; overflow: hidden;
                    img{ width: 100%; height: 100%;  @include transition(.2s all ease-out); cursor: pointer;}
                    &:hover{
                        img{ @include transform(scale(1.1)); }
                    }
                }
                .txt{
                    float: left; width: 50%;padding: 60px 60px 20px; @include boxSizing(border-box);
                }
            }
            .box-small{
                &:after{ content:''; clear: both; display: block; }
            }
            .list-small{
                float: left; width: 23.076%;  @include boxSizing(border-box);
                border:1px solid #ddd; margin-right: 2.564%; position: relative; margin-bottom: 25px;
                &:nth-child(4n){ margin-right:0; }
                .desc{
                    padding: 15px; @include boxSizing(border-box);
                    h5{ font-size:16px; color: #2c6099; line-height: 20px; margin-bottom: 15px; height: 60px;overflow: hidden;}
                    h6{font-size: 13px;color: #666;margin-bottom: 10px;}
                    p{ font-size: 13px; color: #666666; font-weight: 500;line-height: 18px;max-height: 72px;overflow: hidden;}
                    .source{
                        font-size: 13px; color: #999; font-weight: 400;
                    }
                }
                figure{
                    overflow: hidden;
                    img{  @include transition(0.2s all ease-out);  width: 100%;}
                }
                &:after{ content: ''; position: absolute; bottom: 0; left: 0; width:60px; height: 1px; background: #e83626; @include transition(0.2s all ease-out);   }
                &:hover{
                    figure{
                       img{  @include transform(scale(1.2)); @include transition(0.2s all ease-out); }
                    }
                    &:after{ width:100%;  @include transition(0.2s all ease-out); }
                }
            }
            .box-feednews{
                .list-small{
                    &:after{
                        visibility:visible; opacity: 1;
                    }
                    &:hover{
                     border-color:red;  @include transition(.2s all ease-out);
                    &:after{ visibility:hidden; opacity: 0; }
                    }
                }
            }
        }
    }
}
.box-detail-finan{ margin-top: 80px; }
.line-top{
    padding-top: 30px;position: relative;
    &:before{content: "";position: absolute;top: 0;left: -65px;width: 100px;height: 4px;background: $red;}
}

.box-sliderpartner{padding: 50px 0;}
.sliderpartner{
    position: relative;text-align: center;
    .list{
        display: inline-block;width: 16%;
        figure{
            position: relative;text-align: center;
            img{-webkit-filter: grayscale(100%);filter: grayscale(100%);@include transition(.2s all ease-out);opacity: .6;display: inline-block;}
            /*img{position: absolute;bottom: 0;left: 0;right: 0;}*/
        }
        &:hover{
            figure{
                img{-webkit-filter: grayscale(0%);filter: grayscale(0%);opacity: 1;}
            }
        }
    }
    .slick-arrow{
        position: absolute;top: 50%;z-index: 2;font-size: 0;color: transparent;width: 30px;height: 30px;border: 1px solid $blue;@include borderRadius(100%);margin-top: -15px;cursor: pointer;
        &.slick-prev{
            left: -40px;background:$white url('../images/material/prev-arrow-blue.png') no-repeat center;
            &:hover{background:$blue url('../images/material/prev-arrow.png') no-repeat center;}
        }
        &.slick-next{
            right: -40px;background: url('../images/material/next-arrow-blue.png') no-repeat center;
            &:hover{background:$blue url('../images/material/next-arrow.png') no-repeat center;}
        }
    }
}

.business{
    padding: 140px 0;
    .left{
        width: 21.19%;padding-top: 155px;padding-left: 80px;text-align: right;@include boxSizing(border-box);
        h2{margin-bottom: 32px;}
        p{line-height: 30px;}
        .linered{display: inline-block;margin-right: -40px;width: 100px;height: 2px;background: $white;clear: both;}
        .line-top{
            &:before{left:auto;right: -50px;background: $white;}
        }
    }
    .right{width: 74.89%;}
}

.sliderbusiness{
    /*.slick-list{padding-left: 7px;}*/
    .slick-track{margin-left: 7px;}
    .list{
        position: relative;margin: 30px 0;
        figure{
            position: relative;
            &:before{content: "";position: absolute;top:0;bottom: 0;left: 0;right: 0;background: rgba(0,0,0,0.2) url('../images/material/fg-business.png') no-repeat bottom center;background-size: 100%;z-index: 3;@include transition(.2s all ease-out);}
            img{width: 100%;@include transition(.2s all ease-out);}
        }
        .desc{
            position: absolute;top: 50%;left: 0;right: 0;@include transform(translateY(-50%));padding: 0 45px;text-align: center;z-index: 4;
            img{display: inline-block;margin-bottom: 40px;}
            h5{text-transform: uppercase;font-weight: 700;}
            p{padding: 32px 0 0;margin-bottom: 0;line-height: 20px;}
        }
        .btn-box{position: absolute;bottom: 33px;left: 50%;@include transform(translateX(-50%));opacity: 0;visibility: hidden;z-index: 3;}

        &:hover{
            z-index: 2;
            figure{
                &:before{background-color:rgba(232,54,38,.9);}
                img{@include transform(scale(1.03));}
            }
            .btn-box{opacity: 1;visibility: visible;}
        }
    }
}

.awards{
    background: url('../images/material/bg-awards.jpg') no-repeat center;background-size: cover;color: $white;padding: 75px 0 80px;
    h4{line-height: 1.3;}
    .left{width: 80.94%;margin-left: -75px;}
    .right{
        width: 20%;
        .btn-box{max-width: 100%;}
    }
}

.invest{
    background: url('../images/material/bg-invest.jpg') no-repeat center;background-size: cover;padding: 0 0 85px 0;
    .partner-top{margin-bottom: 75px;}
}

.th-invest{
    @include afterboth;
    .ls-invest{
        width: 25%;padding: 0 20px;float: left;@include boxSizing(border-box);text-align: center;position: relative;
        &:before{content: "";position: absolute;top: 20px;bottom: 0;right: 0;width: 1px;background: #cccccc;}
        h2{
            font-size: 50px;font-weight: 500;color: #1d3c74;display: inline-block;
        }
        span{font-size: 28px;font-weight: 500;color: #1d3c74;display: inline-block;}
        p{margin-bottom: 0;font-size: 23px;}
        .icwp{margin-right: 15px;}
        .num-top{margin-bottom: 20px;}

        &:last-child{
            &:before{display:none;}
        }
    }
}

.opportunity{
    position: relative;background-position: center;background-repeat: no-repeat;background-size: cover;min-height: 600px;
    @include afterboth;
    .text{
        padding: 98px 0 0 0;width: 1170px;margin: 0 auto;position: relative;z-index: 2;
        h2{
            margin-bottom: 45px;
            b{color: $blue;}
        }
        .linered{display: inline-block;margin-right: -40px;width: 100px;height: 2px;background: $red;clear: both;}
        p{line-height: 30px;margin-bottom: 38px;}
        .line-top{
            &:before{left: auto;right: -30px;}
        }
        .in-text{padding: 0 0 0 51.28%;text-align: right;}
    }
    figure{
        position: absolute;left: 0;top: 0;bottom: 0;
        img{width: 100%;height: 530px;object-fit: cover;}
    }
}

.latest-update{
    padding:35px 0 30px;
    h4{font-weight: 500;line-height: 1;margin-bottom: 25px;}
    .link-red{font-size: 13px;}
    .left{width: 160px;}
    .right{width: 990px;}
}
.sm-thumb{
    .ls-sm-thumb{
        width: 33.33333333333%;float: left;padding: 0 19px;@include boxSizing(border-box);
        figure{
            float: left;width: 30%;overflow: hidden;
            img{@include transition(.2s all ease-out);}
        }
        .desc{
            float: right;width: 65%;
            span{font-size: 12px;color: #999999;display: block;margin-bottom: 10px;}
            h6{font-size: 16px;line-height: 20px;color: #333;font-weight: 400;margin-bottom: 8px;max-height: 60px;overflow: hidden;}
            p{font-size: 15px;line-height: 18px;}
        }
        &:hover{
            figure{
                img{@include transform(scale(1.1));}
            }
        }
    }
}
.scholarship{
    background-position: center;background-repeat: no-repeat;background-size: cover;min-height: 600px;
    .text{
        padding: 98px 0 50px;width: 1170px;margin: 0 auto;position: relative;z-index: 2;
        h2{
            margin-bottom: 30px;
            b{color: $blue;}
        }
        .linered{display: inline-block;margin-left: -40px;width: 100px;height: 2px;background: $red;clear: both;}
        .line-top{
            &:before{left: -30px;right: auto;}
        }
        .in-text{
            padding: 0 46% 0 0;
            > p{line-height: 30px;margin-bottom: 38px;}
        }
    }
}
.sch-thumb{
    margin: 0 -20px;
    @include afterboth;
    .ls-sch-thumb{
        float: left;width: 50%;padding: 0 20px;@include boxSizing(border-box);
        figure{
            float: left;overflow: hidden;
            img{@include transition(.2s all ease-out);}
        }
        .desc{
            float: right; width: 61%;
            span{font-size: 14px;color: $blue;display: block;margin-bottom: 5px;}
            h6{font-size: 16px;line-height: 20px;color: $red;margin-bottom: 5px;}
            p{font-size: 15px;line-height: 20px;color: #666666;}
        }

        &:hover{
            figure{
                img{@include transform(scale(1.1));}
            }
        }
    }
}

.banner{
    position: relative;
    figure{
        position: relative;
        &:before{content:"";background: rgba(0,0,0,0.1);position: absolute;top: 0;left: 0;right: 0;bottom: 0;}
        img{ width: 100%; }
    }
    .desc{
        position: absolute;top: 50%;@include transform(translateY(-50%));left: 0;right: 0;margin: 0 auto;width: 1600px;max-width: 90%;color: $white;text-transform: uppercase;
        display: none;
        h1{font-size: 57px;font-weight: 700;margin-bottom: 20px;}
        a{font-weight: 700;color: $white;}
        .dots{display: inline-block;width: 7px;height: 7px;background: $white;@include borderRadius(100%);margin: 0 10px;vertical-align: middle;}
    }
}
.pg-down{
    position: absolute; right: 0; bottom:5%; left: 0; display: block; width: 100px; height: 60px; z-index: 3; margin: 0 auto;
    cursor: pointer;
    .arr{
      background: url(../images/material/arr-dwn.png)no-repeat center; width: 17px; height: 22px; position: absolute; 
      top: -30px; right: 0; display: block; left: 0; margin: auto;
    }
    .txt{
        padding-top: 20px;display: block;
    }
    span{ font-size: 12px; color: #fff; padding-top: 30px; position: relative; font-weight: 500; }
}

.sc-padd{
    padding: 98px 0;
    &.nopaddbot{padding-bottom: 0;}
}
.bg-building{background-image: url('../images/material/bg-middle.png');background-repeat: no-repeat; background-size: 100%; background-attachment: fixed;}

.byside{
    margin-bottom: 40px;
    @include afterboth;
    .capt-small{ display: block; font-size: 16px; font-weight: bold; color:#1c2674; margin-bottom: 20px; text-transform: uppercase; }
    h2{
         font-size:40px;color: #333; font-weight:400;
        b,strong{color: $blue;}

    }
    h4{
        font-weight: 300;margin-bottom: 20px;
        b,strong{color: $blue;font-weight: 500;}
    }
    /*p{margin-bottom: 40px;line-height: 24px;}*/
    .left{width: 100%;float: none; margin-bottom:35px; display: block; }
    .right{width:100%; float: none;}
    .linered{display: block;width: 100px;height: 2px;background: $red;margin-left: -40px;}

    &.nominheight{min-height: 0;}
    &.vision-side{
        p{line-height: 24px;margin-bottom: 30px;}
        .left{width: 40%;}
        .right{width: 55%;}
    }
    &.nomarg{
        margin-bottom: 0;
        p{margin-bottom: 0;}
    }
    &.tx-lg{
        h3{margin-bottom: 15px; font-weight: 400;}
        h5{line-height: 34px;font-weight: 300;}
    }
    &.govside{
        position: relative;min-height: 400px;
        figure{background: url('../images/content/bg-governance.png') no-repeat center;}
        > .left{position: absolute;bottom: 0;left: 0;}
    }
}

.box-video{
    position: relative; margin-bottom: 90px;
    iframe,.yvideo{height: 430px;width: 100%;}
    .th-video{
        position: absolute;top: 0;left: 0;right: 0;bottom: 0;overflow: hidden;
        button{
            display: block;border: none;font-size: 0;color: transparent;width: 99px;height: 70px;background: url('../images/material/btn-play-red-lg.png') no-repeat center;
            position: absolute;top: 50%;left: 50%;@include transform(translate(-50%,-50%));cursor: pointer;
        }
        img{ height: 100%; object-fit: cover; width: 100%;}
    }
}

.tx-reliance{
    text-align: center;
    p{margin-bottom: 25px;}
}

.rl-name{
    padding: 100px 0;text-align: center;
    .wrapper{width: 770px;max-width: 100%;}
}

.hunderline{
    position: relative;padding-bottom: 30px;margin-bottom: 33px;
    &:before{content:"";position: absolute;bottom: 0;left: 0;right: 0;height: 2px;width: 100px;background: $red;margin: 0 auto;}
    b,strong{font-weight: 500;color: $blue;}
}

.box-side{
    h4{
        font-size: 33px;line-height: 38px;font-weight: 600;padding-bottom: 30px;margin-bottom: 33px;position: relative;
        &:before{content:"";position: absolute;bottom: 0;left: -21px;width: 70px;height: 2px;background: red;}
    }
    figure{
        float: left;width: 48%;
        /*img{@include boxShadow(0 0 30px 5px rgba(223,223,223,1));}*/
    }
    .text{float: right;width: 48%;padding: 90px 0 0;}
}

.popup{
    position: fixed;top: 0;left: 0;right: 0;bottom: 0;background: rgba(0,0,0,0.7);z-index: 100;display: none;
    .inpopup{
        position: absolute;top: 0;left: 0;right: 0;bottom: 0;margin: 8% auto 5%;width: 1030px;max-width: 90%;background: $white;height: 600px;@include boxSizing(border-box);padding: 60px;
        .close-pop{
            position: absolute;bottom: 100%;right: 0;width: 55px;height: 60px;background: #d7d7d7;cursor: pointer;@include transition(.2s all ease-out);
            span{
                position: absolute;top: 50%;left: 50%;@include transform(translate(-50%,-50%) rotate(45deg));width: 35px;height: 1px;background: #353132;@include transition(.2s all ease-out);
                &:last-child{@include transform(translate(-50%,-50%) rotate(-45deg));}
            }
            &:hover{
                background: #353132;
                span{background: #d7d7d7}
            }
        }
    }
}

.box-linetop{
    padding-top: 110px;border-top: 1px solid #cccccc;
}

.ttl-founder{
    margin-bottom: 100px;text-align: center;font-weight: 300;
    b,strong{font-weight: 500;color: $blue;}
}

.side-profile{
    figure{float: left;width: 40%;}
    .text{
        float: right;width: 53%;padding-top: 40px;
        h4{font-weight: 600;font-size: 33px;margin-bottom: 10px;}
        h5{font-weight: 400;font-style: italic;margin-bottom: 30px;}
        p{line-height: 30px;margin-bottom: 25px;}
        .linered{display: block;margin-left: -50px;width: 100px;height: 2px;background: $red;}
    }
}

.sc-management{
    .top{
        background: #f2f2f2;padding: 170px 0 140px;
        h3{
            margin-bottom: 90px;font-weight: 300;
            b,strong{font-weight: 500;color: $blue;}
        }
        h4{
            font-weight: 300;margin-bottom: 48px;
            b,strong{font-weight: 500;color: $blue;}
        }
        .linered{display: inline-block;width: 100px;height: 2px;background: $red;margin-bottom: 40px;}
    }
    .bottom{
        position: relative;padding-bottom: 110px;
        &:before{content:"";background: #f2f2f2;position: absolute;top: 0;left: 0;right: 0;height: 145px;z-index: -1;}
    }
}

.box-circle{
    margin:0 -15px;text-align: center;
    @include afterboth;
    .ls-circle{
        width: 24%;padding: 0 15px;@include boxSizing(border-box);margin-bottom: 60px;display: inline-block;
        figure{overflow: hidden;@include borderRadius(100%);margin-bottom: 43px;border: 2px solid #f2f2f2;}
        h6{font-weight: 600;font-size: 23px;margin-bottom: 5px;}
        p{margin-bottom: 0;font-style: italic;}
        .desc{text-align: center;}

        &:nth-child(4n+1){clear: both;}
    }
}

.visimisi{
    text-align: center;padding: 100px 0 110px;
    h3{
        margin-bottom: 35px;font-weight: 400;
        b,strong{font-weight: 500;color: $blue;}
    }
    h4{line-height: 40px;font-weight: 400;}
    p{line-height: 34px;font-weight: 400;font-size: 24px;margin-bottom: 16px;}
    ul{
        li{
            font-size: 20px;line-height: 30px;position: relative;padding-left: 20px;text-align: left;
            @include afterboth;
            &:before{content:"";position: absolute;top: 16px;left: 0;width: 8px;height: 8px;background: #333;@include borderRadius(100%);}
        }
    }
    .linered{display: inline-block;width: 100px;height: 2px;background: $red;margin-bottom: 40px;}
}

.box-milestone{
    position: relative;margin: 60px 0 100px;
    &:before{content:"";position: absolute;top: 35px;bottom: 0;left:50%;background: #a7a7a7;margin-left: -1px;width: 2px;}
    .ls-milestone{
        position: relative;@include afterboth;
        &:before{content:"";position: absolute;top: 20px;left: 50%;width: 12px;height:12px;border: 2px solid #a7a7a7;background: $white;@include borderRadius(100%);margin-left: -8px;@include transition(.2s all ease-out);}
        h3{font-weight: 300;margin-bottom: 20px;@include transition(.2s all ease-out);}
        /*p{line-height: 30px;}*/
        ul{
            li{
                line-height: 30px;position: relative;padding-right: 15px;
                &:before{content:"";position: absolute;top: 12px;right: 0;width: 5px;height: 5px;background: #333;@include borderRadius(100%);}
            }
        }
        .in-milestone{
            width: 50%;float: left;@include boxSizing(border-box);padding: 0 55px 0 30px;text-align: right;
        }

        &.slick-current,&.active{
            /*&:before{background:$darkblue;width: 20px;height: 20px;margin-left: -12px;top: 18px;}*/
            &:before{background:transparent;width: 20px;height: 20px;margin-left: -12px;top: 18px;border-color: transparent;}
            h3{font-weight: 700;color: $darkblue;font-size: 50px;}
        }
        &:nth-child(even){
            ul{
                li{
                    padding-right: 0;padding-left: 15px; 
                    &:before{right: auto;left: 0;}
                }
            }
            .in-milestone{
                padding: 0 30px 0 55px;float: right;text-align: left;
            }
        }
    }
    .dots{position: absolute;top: 20px;left: 50%;width: 20px;height:20px;border: 2px solid #a7a7a7;background: $darkblue;@include borderRadius(100%);margin-left: -12px;z-index: 2;}

    .slick-arrow{
        position: absolute;left: 50%;margin-left:-10.5px;font-size: 0px;color: transparent;border: none;background-repeat: no-repeat;background-position: center;background-size: 100%;padding: 0;background-color: transparent;
        width: 21px;height: 13px;z-index: 2;cursor: pointer;
        &.slick-prev{
            background-image: url('../images/material/top-arrow.png');top: -50px;
            &.slick-disabled{background-image: url('../images/material/top-arrow-grey.png');}
        }
        &.slick-next{
            background-image: url('../images/material/bottom-arrow.png');bottom: -50px;
            &.slick-disabled{background-image: url('../images/material/bottom-arrow-grey.png');}
        }
    }

    &.fixed{
        .dots{position: fixed;top: 350px;}
    }
}

.potrait{
    @include afterboth;
    .ls-potrait{
        width: 33.33333333333333%;float: left;position: relative;
        figure{
            position: relative;
            &:before{content: "";position: absolute;top: 0;left: 0;right: 0;bottom: 0;background: rgba(0,0,0,0.2) url('../images/material/fg-potrait.png') no-repeat bottom center;background-size: 100%;@include transition(.2s background ease-out);}
        }
        .desc{
            position: absolute;top: 50%;left: 0;right: 0;text-align: center;@include transform(translateY(-50%));color: $white;
            img{margin-bottom: 20px;}
            h6{font-weight: 700;text-transform: uppercase;}
        }

        &:nth-child(3n+1){clear: both;}
        &:hover{
            figure{
                &:before{background-color:rgba(0,0,0,0.5);}
            }
        }
    }
}

.top-text{
    h3{
        margin-bottom: 20px;font-weight: 300;
        b,strong{font-weight: 500;color: $blue;}
    }
    /*p{line-height: 30px;}*/
    .linered{width: 100px;height: 2px;background: $red;margin-left: -50px;}
}

.bot-grey{
    position: relative;
    &:before{content:"";position: absolute;bottom: 0;left: 0;right: 0;height: 40%;background: #f6f6f6;}
}

.landscape{
    .ls-landscape{
        margin-bottom: 50px;@include afterboth;
        h5{font-weight: 700;font-size: 22px;margin-bottom: 5px;}
        h6{color: $blue;font-weight: 600;font-size: 18px;}
        p{color: #555555;margin-bottom: 25px;}
        figure{float: left;width: 225px;}
        .text{float: right;width: 700px;padding-top: 10px;}
    }
}

.wpcontent{
    h2,h3{
        font-weight: 300;
        b,strong{font-weight: 500;color: $blue;}
    }
    h6{font-weight: 600;}
    .linered{display: block;width: 100px;height: 2px;background: $red;margin-left: -50px;margin-top: 20px;}
    
    ol{
        counter-reset: my-badass-counter;
        li{
            position: relative; line-height: 30px; padding-left: 15px;
            &:before{content: counter(my-badass-counter); counter-increment: my-badass-counter; position: absolute; top: 0; left: 0;color: #4675cd;}
        }
    }
    ul{
        li{
            position: relative; line-height: 30px; padding-left: 15px;
            &:before{content: ""; position: absolute; top: 12px; left: 0; width: 6px; height: 6px; @include borderRadius(100%); background: $blue;}
        }
    }
}
.quotes{
    padding: 0 115px;font-weight: 300;font-size: 33px;line-height: 38px;position: relative;
    &:before{content:"";position: absolute;top: 10px;left: 70px;width: 28px;height: 20px;background: url('../images/material/ic_quotes.png') no-repeat center;background-size: 100%;}
    b,strong{font-weight: 500;color: $blue;}
}

.box-docs{
    .ls-docs{
        background: #eceef1;margin-bottom: 10px;vertical-align: middle;cursor: pointer;display: block;
        @include afterboth;
        .icwp{vertical-align: middle;}
        .date,.text{@include boxSizing(border-box);padding: 17px 20px;line-height: 23px;}
        .date{
            float: left;width: 138px;text-align: center;position: relative;
            &:before{content: "";position: absolute;top:0;bottom: 0;right: 0;width: 1px;background: #acacac;height: 20px;margin: auto 0;}
        }
        .text{
            float: right;width: 1020px;position: relative;padding-right: 50px;
            .icwp{margin-right: 10px;}
            .lk-download{
                position: absolute;top: 0;right: 20px;bottom: 0;margin: auto 0;height: 23px;
                .icwp{margin-right: 0;}
            }
        }

        /*&:hover{@include boxShadow(0 4px 20px 0px rgba(0,0,0,0.1));}*/
        &:hover{
            background: #e3e7ec;
            .icwp.ic_download{background-image: url('../images/material/ic_download_black.png');}
        }
    }
}

.box-form{
    input[type=text],
    input[type=email],
    input[type=password],
    input[type=number],
    textarea,select{border: 1px solid #aaa;height: 45px;padding: 0 15px;@include boxSizing(border-box);width: 100%;@include borderRadius(5px);}
    textarea{height: 115px;padding: 15px;resize: none;}
    select{background-image: url('../images/material/arrow-select.png');background-position: right center;background-repeat: no-repeat;padding-right: 40px;}

    .nt-info{display: block;margin: 10px 0 5px;font-style: italic;font-size: 13px;color: #919191;}
    .col{margin-bottom: 20px;}
    .mandatory{color: red;}
    &.form-contact{
        margin-bottom: 60px; padding-bottom: 40px; border-bottom: 1px solid #cccccc; margin-top: 40px;
        h2{ font-size: 33px; line-height: 40px; margin-bottom: 20px; font-weight: 400;}
        hr{margin: 30px 0;border:none;height: 1px;background: #cccccc;}
        label{
            line-height: 40px;font-weight: 500;position: relative;display: block;padding-right: 5px;
            &:before{content:":";position: absolute;top: 0;right: 0;}
        }
    }
}
.info-text{display: block;font-size: 13px;color: #666666;padding: 5px 0;}
.add-btn{
    display: block;position: relative;text-decoration: underline;font-size: 13px;color: #048cc5;padding-left: 24px;
    &:before{content:"";position: absolute;top: 0;left: 0;width: 15px;height: 15px;background: url('../images/material/btn-add.png')no-repeat center;}
    &:hover{color: #048cc5;}
}
.radio-custom{
    position: relative; padding-left:25px; margin: 0; cursor: pointer; height: 40px;color: #333333;font-size: 16px;margin-right: 20px;line-height: 40px;
    input[type=radio]{
        background: url(../images/material/ic_radio.png) no-repeat; display: inline-block; vertical-align: middle; margin:0; padding: 0; width:16px; height:16px; position:absolute; top:2px; left:0; cursor: pointer;
        &:checked, &[checked=checked]{background-position: right center;}
    }
}
.input_file{
    position: relative;
    input[type=file]{opacity: 0;position: absolute;top: 0;left: 0;right: 0;bottom: 0;z-index: 2;width: 100%;}
    span{
        display: block;height: 45px;line-height: 45px;background: $white;@include borderRadius(5px);border: 1px solid #d7d7d7;@include boxSizing(border-box);position: relative;padding:0 40px 0 15px;
        &:before{content:"";position: absolute;top: 0;right: 15px;bottom: 0;width: 25px;background: url('../images/material/ic_upload.png') no-repeat center;}
    }
    &.style2{
        min-width: 178px;width: auto;display: inline-block;
        span{
            background: #0ba7e8;border:none;color: $white;@include borderRadius(5px);padding-left: 65px;padding-right: 10px;font-size: 13px;font-weight: 500;height: 40px;line-height: 40px;letter-spacing: 1px;
            &:before{right:auto;left: 0;background: #048cc5 url('../images/material/ic_upload_folder.png')no-repeat center;width: 45px;@include borderRadius(5px 0 0 5px);}
        }
    }
}

.contact-side{
    padding-bottom: 65px;border-bottom: 1px solid #cccccc;margin-top: 60px;
    &:after{content:"";clear: both;display: block;}
    h6{font-weight: 700;}
    .left,.right{@include boxSizing(border-box);}
    .left{width: 40%;}
    .right{width: 55%;}

    &.bg-wh{
        .right{padding: 0 30px;width: 60%; @include boxSizing(border-box);}
    }
    .box{
        margin-bottom: 25px; 
        h4{ margin-bottom: 10px; font-size: 23px; color: #000; font-weight: 500;}
        p{ margin-bottom: 0; }
        a{ 
            color:#4675cd;
            &.wa{ color: #000; }
          }
    }
}
.address{
    line-height: 28px;
    a{color: #333;}
    .left{width: 55%;}
    .right{width: 40%;}
}
.box-sorting{
    margin-bottom: 60px;
    @include afterboth;
    a{
        font-size: 24px;color: #999999;display: inline-block;padding: 0 28px;position: relative;
        &:before{content:"";width: 1px;background: #b7b7b7;position: absolute;top: 0;bottom: 0;right: 0;}
        &:first-child{padding-left:0;}
        &:last-child{
            padding-right:0;
            &:before{display:none;}
        }

        &:hover,&.active{color:#333333;}
    }
}

.box-search{
    position: relative;
    input[type=text]{
        border: 1px solid #d7d7d7;height: 35px;padding: 0 30px 0 15px;width: 300px;@include borderRadius(5px);@include boxSizing(border-box);
        @include andplaceholder{font-style: italic;}
    }
    .btn-search{position: absolute; top: 50%;right: 10px;background: url();border: none;padding: 0;width: 20px;height: 20px;background: url('../images/material/ic_search_grey.png') no-repeat center;margin-top: -10px;}
}
.box-filter{
    position: relative;
    select{background:url('../images/material/arrow-select-sm.png') no-repeat center right;height: 35px;padding: 0 40px 0 15px;min-width: 150px;}
}

.box-news{
    margin: 0 -20px;
    @include afterboth;
    .ls-news{
        width: 50%;padding: 0 20px;float: left;@include boxSizing(border-box);margin-bottom: 50px;
        >figure{float: left;width: 150px;}
        .desc{
            float: right;width: 380px;
            span{display: block;margin-bottom: 5px;}
            h6{line-height: 30px;max-height: 60px;overflow: hidden;font-weight: 600;color: $darkblue;margin-bottom: 5px;}
            p{line-height: 24px;max-height: 96px;overflow: hidden;}
        }

        &:nth-child(odd){clear: both;}
    }

    &.noimage{
        .ls-news{
            >figure{display: none;}
            .desc{width: 100%;}
        }
    }
}

.pagination{
    text-align: center;
    a{
        display: inline-block;color: #333333;margin: 0 13px;vertical-align: middle;line-height: 30px;
        &.active{color: $red;font-weight: 600;}
        &:hover{color: $red;}
        &.prev,&.next{
            position: relative;
            &:before{content: "";position: absolute;top: 0;bottom: 0;width: 1px;background: #d7d7d7;}
            &:after{content: "";position: absolute;top: 0;bottom: 0;width: 16px;height: 11px;margin:auto 0;background-repeat: no-repeat;background-position: center;}
        }
        &.prev{
            margin: 0 10px 0 0;padding: 0 20px 0 30px;
            &:before{right:0;}
            &:after{background-image: url('../images/material/prev-pagging.png');left: 0;}
            &.disable{
                &:after{background-image: url('../images/material/prev-pagging-grey.png');}
            }
        }
        &.next{
            margin: 0 0 0 10px;padding: 0 30px 0 20px;
            &:before{left:0;}
            &:after{background-image: url('../images/material/next-pagging.png');right:0;}
            &.disable{
                &:after{background-image: url('../images/material/next-pagging-grey.png');}
            }
        }
        &.disable{color: #d7d7d7;}
    }
}

.sosmed-inline{
    padding: 18px 0;border: 1px solid #cacaca;border-left: none;border-right: none;margin-bottom: 50px;
    a{
        &.icwp{
            width: 72px;border-right: 1px solid #e5e5e5;
            &:last-child{border-right:none;}
        }
    }
}

.slider-career{
    position: relative;
    .list{
        position: relative;background: #ebebeb;margin-top: 50px;min-height: 390px;
        h5{font-weight: 600;margin-bottom: 10px;}
        h6{font-weight: 600;font-style: italic;color: $blue;margin-bottom: 25px;font-size: 18px;}
        p{font-size: 16px;line-height: 24px;}
        >figure{position: absolute;bottom: 0;left: 135px;}
        .desc{
            padding: 88px 100px 0 520px;
        }
    }

    .slick-arrow{
        position: absolute;top: 50%;width: 60px;height: 60px;background-color: $blue;@include borderRadius(100%);border: 1px solid $blue;padding: 0;font-size: 0;color: transparent;z-index: 2;
        background-repeat: no-repeat;background-position: center;cursor: pointer;
        &.slick-prev{
            left: -20px;background-image: url('../images/material/prev-arrow-light.png');
            &:hover{background-color:$white;background-image: url('../images/material/prev-arrow-light-blue.png');}
        }
        &.slick-next{
            right: -20px;background-image: url('../images/material/next-arrow-light.png');
            &:hover{background-color:$white;background-image: url('../images/material/next-arrow-light-blue.png');}
        }
    }
    &.margbot{margin-bottom: 110px;}
}

.career-list{
    margin: 0 -9px;
    @include afterboth;
    .ls-career{
        display: table;float: left;width: 20%;padding: 0 9px;@include boxSizing(border-box);margin-bottom: 20px;vertical-align: middle;
        span{display: block;height: 90px;border: 2px solid #e1e1e1;display: table-cell;vertical-align: middle;text-align: center;word-wrap: break-word;font-size:20px;line-height: 24px;color: #333333;@include transition(.2s all ease-out);@include borderRadius(5px);}

        &:hover{
            span{background: $blue;color: $white;border-color: $blue;}
        }
    }
}

.ttl-career{
    text-align: center;
    h5{line-height: 34px;}
    .linered{
        &.a-center{margin-left: 0;display: inline-block;}
    }
}

.career-lands-list{
    .ls-career-lands{
        margin-bottom: 18px;border: 2px solid #e1e1e1;padding: 45px 0;
        @include afterboth;
        h5{line-height: 30px;font-weight: 600;margin-bottom: 30px;}
        .linered{margin: 0;height: 2px;width: 70px;}
        .left,.center,.right{@include boxSizing(border-box);}
        .left{padding: 0 20px 0 50px;width: 300px;margin-right: 170px;}
        .center{float: left;width: 300px;}
        .right{text-align: right;width: 250px;padding-right: 40px;}
    }
}

.row-career{
    margin-bottom: 10px;
    @include afterboth;
    label{
        position: relative;padding-right: 5px;width: 40%;display: block;float: left;clear: both;line-height: 24px;@include boxSizing(border-box);color: #999999;
        &:before{content: ":";position: absolute;top: 0;right: 0;}
    }
    p{float: right;width: 55%;margin-bottom: 0;display: block;line-height: 24px;}
    a{font-weight: 700;color: $blue;font-size: 18px;}
}

.career-form{
    background: #f1f1f1;padding: 55px 150px;
}

.map{width: 100%;height: 475px;@include boxShadow(0 0 40px 10px rgba(0,0,0,0.15));}

.accdown{
    margin-bottom: 10px;
    .btn-accdown{
        background: #eceef1;padding: 15px 25px;font-weight: 500;position: relative;padding-right: 45px;cursor: pointer;@include transition(.2s all ease-out);
        &:before{content: "+";position: absolute;top: 8px;right: 10px;width: 30px;height: 30px;background: $darkblue;@include borderRadius(5px);color: $white;line-height: 30px;text-align: center;text-transform: uppercase;}
        &:hover{background: #e3e7ec;}
    }
    .drop-accdown{
        padding: 15px 25px;display: none;
        /*p{font-size: 16px;line-height: 30px;}*/

    }

    &.active{
        .btn-accdown{
            &:before{content: "-";}
        }
        .drop-accdown{display: block;}
    }
}

.box-phone{
    background: $darkblue;@include borderRadius(5px);padding: 20px 40px 20px 80px;color: $white;display: inline-block;line-height: 24px;position: relative;
    &:before{content:"";position: absolute;top: 28px;left: 25px;width: 30px;height: 30px;background: url('../images/material/ic_telp.png') no-repeat center;}
    a{color: $white;}
}

.loader{
    position: fixed;top: 0;left: 0;bottom: 0;right: 0;z-index: 1000;overflow: hidden;background: #c5c5c5;
    .in-loader{
        position: absolute;top: 50%;left:0;right: 0;width: 800px;margin: -45px auto 0;height: 269px;@include transform(translateY(-50%));
        .el{position: absolute;bottom: 0;left: 38%;width: 123px;height: 170px;background: url('../images/material/l-logo.png') no-repeat center;opacity: 0;background-size: 100%;}
        .ii{position: absolute;top: 100px;right: 38%;width: 123px;height: 165px;background: url('../images/material/i-logo.png') no-repeat center;opacity: 0;background-size: 100%;}
        .dot{position: absolute;top: -500px;right: 38%;width: 31px;height: 31px;background: $darkblue;@include borderRadius(100%);opacity: 0;}
        .text-logo{position: absolute;top: 108px;left: 50%;width: 815px;height: 90px;background: url('../images/material/text-logo.png') no-repeat center;background-size: 100%;margin-left: -403px;opacity: 0;background-size: 100%;}
    }
}

.potrait-list{
    margin:0 -15px;
    @include afterboth;
    .ls-potrait{
        width: 33.3333333333333333333333333%;float: left;padding: 0 15px;@include boxSizing(border-box);margin-bottom: 30px;
        h4{font-weight: 600;line-height: 38px;font-size: 33px;margin-bottom: 20px;}
        p{height: 96px;overflow: hidden;margin-bottom: 20px;}
        .linered{display: inline-block;margin-left: -25px;width: 70px;height: 2px;background: $red;clear: both;margin-bottom: 20px;}
        .in-potrait{padding: 40px 50px 45px;border: 2px solid #e1e1e1;}
        .imgpop,.textpop{display: none;}

        &:nth-child(3n+1){clear: both;}
    }
}
.box-name{
    position: relative;
    @include afterboth;
    .list-member{
        float: left; width: 31.168%; margin-right: 2.136%; border:1px solid #d8d8d8; margin-bottom: 25px; 
        position: relative;
        &:nth-child(3n){ margin-right: 0; }
        figure{
             cursor: pointer;
            img{ filter: grayscale(1); @include transition(0.2s all ease-out); width: 100%; }
        }
        .data-pop{display: none;}

        &:nth-child(3n+1){clear: both;}
        &:hover{
            figure{
                img{ filter: grayscale(0); }
            }
        }
    }
    .text{
        padding: 15px 30px; @include boxSizing(border-box);
        span{
            display: block; text-align: center;
            &.name{ font-size:16px; color:#111; margin-bottom: 5px;line-height: 20px;max-height: 40px;overflow: hidden;word-wrap: break-word;}
            &.post{ font-size: 13px; color: #999999; font-style: italic;line-height: 16px;max-height: 48px;overflow: hidden;word-wrap: break-word;}
        }
    }
    .content-profile{
        position: absolute; top:auto; bottom: 0; left: 0; right: 0; width: 100%;
        h3{ font-size:28px; color: #333333; margin-bottom: 10px;  }
        span{ font-style: italic; display: block;   color: #999;}
        p{ font-size: 16px; color: #333333; font-weight: 500; }
        .tittle{
            padding-bottom: 20px; position: relative;
            &:after{ content:''; position: absolute; bottom: 0; left: -30px; width: 100px; height: 2px; background: #e73726; }
        }
    }
}
.box-value{
    .list-value{
        margin-bottom: 30px;
         @include afterboth;
    }
    figure{
        float: left; overflow: hidden;
        img{ width: 100%; @include transition(0.2s all ease-out); }
        &:hover{ 
            img{ @include transform(scale(1.2)); @include transition(0.2s all ease-out); }
        }
    }
    .txt{
        float: left; width:61.038%; padding: 40px 35px 0; @include boxSizing(border-box);
        h3{ font-size: 28px; color: #1c2674; font-weight: 500; margin-bottom: 25px; }
    }
}
.tx-Reliance{
    h4{ font-size: 23px; color: #4675cd;  margin-bottom: 25px; font-weight: 500; }
    p{ font-size: 18px; color: #333; font-weight: 400; }
    ol,ul{
        li{
            padding-left: 45px;margin-bottom:35px;color: #333333; font-size: 18px; position: relative; line-height: 28px;
            &:before{position: absolute; left: 0; top:0px;}
        }
    }
    ol{
        li{
            counter-increment: step-counter;
            &:before{ content:counter(step-counter);font-size: 28px; font-weight: 500; color:#4675cd;display: block;}
        }
    }
    ul{
        li{
            &:before{content:"";top: 9px;left: 10px;width: 10px;height: 10px;@include borderRadius(100%);background: $blue;}
        }
    }
}
.box-financial{
    margin-top:80px; display: block;
    .list{
        padding: 20px 10px 45px; border-bottom: 1px solid #cccccc; margin-bottom:20px; 
        @include afterboth;
        &:hover{
            @include boxShadow(0 0 12px -5px #000); @include transition(.2s all ease-out);
        }
    }
    figure{
        float: left; width: 25.974%; margin-right: 4.545%;
    }
    .txt{ 
        float: left; width: 68.831%;
        h5{ font-size: 20px; color:#111; margin-bottom: 20px; font-weight: 500; text-transform: uppercase; letter-spacing: 1px; }
        p{ font-size:16px; color: #666666;  }
    }
    .btn{
        &.readmore{
            padding: 0 40px; line-height: 30px; font-size: 13px; color: #fff; @include boxSizing(border-box); background: #1c2674; display: inline-block;
            text-transform: uppercase; cursor: pointer; font-weight: 500; letter-spacing: 1px;  @include transition(0.2s all ease-out);
        }
        &:hover{
            &.readmore{
                background: #e84131; @include transition(0.2s all ease-out);
            }
        }
    }
}
.box-detail-finan{
    .list{
        padding-bottom: 35px; border-bottom: 1px solid #d7d7d7; margin-bottom: 35px;
    }
    figure{
        margin-bottom: 40px;
    }
    .txt{
        h4{ font-size: 22px; color: #4675cd ; margin-bottom: 30px;font-weight: 600;}
        p{ font-size: 18px; color:#333; margin-bottom: 35px;  }
        a{
            padding-left:20px; display: inline-block; font-size: 13px; color: #1c2674; font-weight: 600; position: relative;

            &:before{ 
                content:''; position: absolute; left: 0; top: 0; bottom: 0; margin: auto 0; background: url(../images/material/ic-hyperlink.png)no-repeat center;
                 width: 14px; height: 14px; 
             }
         }
    }
}
.box-career{
    margin-top: 40px;
    .row{
        margin-bottom: 25px;
        @include afterboth;
        .col{
            width: 48%; margin-right: 4%; float: left; padding: 25px 20px; margin-bottom: 25px; @include boxSizing(border-box);
            border: 1px solid #eaeaea; position: relative; @include transition(0.2s all ease-out);
            &:after{
                content:''; position: absolute; left: 0; bottom: 0; width: 60px; height: 1px; background: $darkblue;
            }
            &:nth-child(2n){ margin-right: 0; }
            span{ font-size:16px; color: #666666; display: block;  }
            &:hover{
                border-color: $darkblue; background: $darkblue; @include transition(0.2s all ease-out);
                @include boxShadow(5px 5px 20px -10px #000);
                &:after{
                    background:$darkblue;
                }
                h5{ color: #fff; }
                span{ color:#fff; }
            }
        }
        h5{ font-size: 16px; color: #2c6099; margin-bottom: 10px; }
    }
}

.wrap-news{
    @include afterboth;
    margin: 0 -10px 50px;
    .box{ 
        border: 1px solid #eaeaea; position: relative;
        &:after{ content:''; position: absolute; left: 0; bottom: 0; width: 57px; height: 1px;  background: #e83626;  @include transition(0.2s all ease-out);}
     }
    .list-news{
        float: left; width:33.333%; padding: 0 10px; @include boxSizing(border-box);
        margin-bottom: 25px;
        figure{   
            overflow: hidden;position: relative;
            img{  @include transition(0.2s all ease-out); }
            .btn-play{width: 55px;height: 39px;position: absolute;top: 0;left: 0;right: 0;bottom: 0;margin: auto;cursor: pointer;background: url('../images/material/btn-play-red.png')no-repeat center;}
        }
        &:hover{
            .box{
                &:after{ width:100%;  @include transition(0.2s all ease-out); }
            }
            figure{
                img{ @include transform(scale(1.1)); @include transition(0.2s all ease-out); }
            }
        }

        &:nth-child(3n+1){clear: both;};
    }
    figure{
        img{ width: 100%;  }
    }
    .txt{ 
        padding:10px 15px; @include boxSizing(border-box);  
        >h5{max-height: 60px;overflow: hidden;}
        >p{max-height: 64px;overflow: hidden;}
    }
    .date{ font-size: 14px; color:#666; display: block; margin-bottom: 10px;  }
    h5{ font-size: 16px; color: #2c6099; font-weight: 400; margin-bottom: 10px;line-height: 20px;}
    p{ font-size: 13px; color: #666; line-height: 16px;}

    &.box-videos{
        .data-pop{display: none;}
    }
}
.box-partner{
    margin-top: 60px;
    @include afterboth;
    .list{
        float: left; width: 30.519%; margin-right: 3.79%; cursor: pointer; position: relative;margin-bottom: 30px;
        border:1px solid #d7d7d7; @include transition(0.2s all ease-out);
        &:after{
            content:''; background:#e83626; width: 60px; height: 1px; position: absolute; bottom: -1px; left: 0;
             @include transition(0.2s all ease-out);
        }
        &:nth-child(3n){ margin-right: 0; }
        &:nth-child(3n+1){clear: both;}
        &:hover{
            border-color: #e83626; @include transition(0.2s all ease-out);
            &:after{ display:none;  @include transition(0.2s all ease-out); }
        }
    }
    .data-pop{ display: none; }
}

/* Popup
----------------------------------------------------------------------------------------------*/

.popmiddle {
   position: fixed;top: 0;left: 0;right: 0;bottom: 0;z-index: 100;display: none;
   background: rgba(0,0,0,0.3);
    .inpopup{
        position: relative;top: 0;left: 0;right: 0;margin: 8% auto 5%;width: 990px;max-width: 90%;
        background: $white;height: auto;@include boxSizing(border-box);padding: 45px 30px; overflow: hidden;
        @include boxShadow(0px 0px 35px -15px #000);
        .close-pop{
            position: absolute;top: 0;right: 0;width: 55px;height: 60px;cursor: pointer;@include transition(.2s all ease-out);
            span{
                position: absolute;top: 50%;left: 50%;@include transform(translate(-50%,-50%) rotate(45deg));width: 25px;height: 1px;background: #353132;@include transition(.2s all ease-out);
                &:last-child{@include transform(translate(-50%,-50%) rotate(-45deg));}
            }
            &:hover{
                span{background: #000; width: 15px;}
            }
        }
        .left-content{
            float: left; width: 35%; margin-right: 0;
            figure{
                margin-bottom: 20px;
                img{width: 100%;}
            }
            a{ 
                display: block; font-size: 12px; color: #e73726; padding-left: 25px; position: relative;
                margin:10px 0; font-weight: 500;
                &.email{
                    &:before{ 
                        content:''; position: absolute; left: 0; top: 0; bottom: 0; margin: auto 0; width:19px; height: 14px;
                        background: url(../images/material/ic-mail.png)no-repeat center;
                      }
                }
                &.lk{
                    &:before{ 
                        content:''; background: url(../images/material/ic-lk.png)no-repeat center; width: 17px; height: 16px;
                        position: absolute; left: 0; top: 0; bottom: 0; margin: auto 0;
                    }
                }
            }
        }
        .text{ 
            float: right; width: 60%;max-height: 500px;overflow: auto;
            h4{ font-size: 28px; color: #333333; font-weight: 500; margin-bottom: 15px;}
            small{
            font-style: italic; font-size: 16px; color: #999999; display: block; padding-bottom: 15px; margin-bottom: 25px; 
            position: relative;
                &:before{ 
                    content:''; position: absolute; left:-30px; width: 100px; height: 2px; background: #e73726; bottom: 0;
                  }
            }
            p{ font-size: 16px; font-weight: 400; color: #333; }
        }
    }

}

.pop{
    position: fixed;top: 0;left: 0;right: 0;bottom: 0;z-index: 100;display: none;
    background: rgba(0,0,0,0.3);
    .inpopup{
        position: relative;top: 0;left: 0;right: 0;bottom: 0;margin: 5% auto 5%;width: 770px;max-width: 90%;background: $white;height: auto;@include boxSizing(border-box);padding: 45px 40px; overflow: auto;max-height: 80vh;
        @include boxShadow(0px 0px 35px -15px #000);
        .close-pop{
            position: absolute;top: 0;right: 0;width: 45px;height: 50px;cursor: pointer;@include transition(.2s all ease-out);
            span{
                position: absolute;top: 50%;left: 50%;@include transform(translate(-50%,-50%) rotate(45deg));width: 25px;height: 3px;background: #353132;@include transition(.2s all ease-out);
                &:last-child{@include transform(translate(-50%,-50%) rotate(-45deg));}
            }
            &:hover{
                span{background: #000; width: 15px;}
            }
        }
        .text{ 
            h4{ 
                font-size: 28px; color: #4675cd; font-weight: 500; margin-bottom: 15px; position: relative;
                padding-bottom:20px;
                &:before{ 
                    content:''; position: absolute; left:-15px; width: 60px; height: 2px; background: #e73726; bottom: 0;
                }
            }
            a{ font-style: italic; font-size: 18px; display: block; color: #4675cd; }
            p{ font-size: 16px; font-weight: 400; color: #333; }
        }
    }
    .box-video{width: 100%; margin-top: 20px;margin-bottom: 0;}
    iframe{width: 100%; height: 300px;}

    .container-video {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &.mapdetail{
        .inpopup{width: 1440px;max-width: 90%;margin: 0 auto;position: absolute;top: 50%;@include transform(translateY(-50%));bottom: auto;}
    }
    &#popHome{
        .inpopup{
            overflow: inherit;padding: 2px;height: 474px;margin: auto;max-height: none;position: absolute;
            .close-pop{
                background-color: $red;right: auto;left: 100%;
                span{background: $white;}
            }
            .box-pop{
                img{vertical-align: middle;height: 470px;object-fit: cover;width: 100%;}
            }
        }
    }
    &#popThanks{
        .inpopup{
            width: 600px;text-align: center;top: 50%;@include transform(translateY(-50%));margin: 0 auto;
            h2{
                position: relative;padding-bottom: 20px;margin-bottom: 30px;color: $blue;
                &:before{ 
                    content:''; position: absolute; left:0;right: 0;margin: 0 auto;width: 60px; height: 2px; background: #e73726; bottom: 0;
                }
            }
        }
    }
}

.box-acc{
    .list-acc{
        margin-bottom: 20px;border:1px solid #eaeaea;
        .btn-acc{
            padding: 28px 45px 28px 25px;position: relative;cursor: pointer;@include transition(.2s all ease-out);
            &:before{content: "";position: absolute;bottom: -1px;left: 0;height: 1px;background: $darkblue;width: 60px;@include transition(.2s all ease-out);}
            h6{color: #2c6099;font-weight: 500;font-size: 16px;@include transition(.2s all ease-out);}
            p{color: #666666;font-size: 14px;font-style: italic;margin-bottom: 0;@include transition(.2s all ease-out);}
            .plus{
                position: absolute;top: 0;right: 20px;bottom:0;width: 25px;height: 25px;@include borderRadius(100%);background: $darkblue;margin: auto 0;@include transition(.2s all ease-out);
                span{
                    display: block;position: absolute;top: 0;left: 0;right: 0;bottom: 0;margin: auto;width: 14px;height: 2px;background: #fff;@include transition(.2s all ease-out);
                    &:last-child{@include transform(rotate(90deg));}
                }
            }
        }
        .ctn-acc{
            padding: 38px 25px;border-top: 1px solid #eaeaea;display: none;
            h5{font-size: 20px;color: #111111;font-weight: 500;letter-spacing: 1px;margin-bottom: 10px;}
            ul,ol{
                margin-bottom: 20px;
                li{font-size: 16px;line-height: 26px;color: #666666;}
            }
            ul{
                li{
                    &:before{top:10px;}
                }
            }
        }
        
        &:hover,&.active{
            .btn-acc{
                color: #fff;background:$darkblue;
                &:before{width:100%;}
                h6,p{color: #fff;}
                .plus{
                    background: #fff;
                    span{background: $darkblue;}
                }
            }
        }
        &.active{
            .btn-acc{
                .plus{
                    span{
                        &:last-child{@include transform(rotate(0deg));}
                    }
                }
            }
            .ctn-acc{display: block;}
        }
    }
}

.listdata{
    margin-bottom: 60px;
    .list{
        padding: 35px 30px;border-bottom: 1px solid #cccccc;
        h5{margin-bottom: 10px;}
        p{margin-bottom: 0;}

        &:last-child{border-bottom: none;}
    }
}
.headerror{
    position: fixed;top: 0;left: 0;right: 0;padding: 24px 0;border-bottom: 1px solid #ebebeb;background: $white;z-index: 10;text-align: center;
}
.contenterror{
    height: 100vh;position: relative;
    .mid{
        position: absolute;top: 50%;left: 50%;@include transform(translate(-50%,-50%));text-align: center;
        h4{font-size: 28px;color: #9e9e9e;margin-bottom: 40px;}
        h5{font-size: 26px;color: #333333;margin-bottom: 70px;}
        figure{margin-bottom: 40px;}
    }
}

.fixbtn{
    position: fixed;bottom: 0px;right: 0;z-index: 100; padding: 10px 30px 20px;width: 120px;background: $white;display: block;@include transition(.2s all ease-out);cursor: pointer;
    &:before{content:"";position: absolute;bottom: 0;left: 0;right: 0;height: 10px;background: $darkblue;@include transition(.2s all ease-out);z-index: 1;}
    &:after{content:"";position: absolute;top: 36px;left: 0;right:0;border-left: 4px solid transparent;border-right: 4px solid transparent;border-bottom: 4px solid $darkblue;margin: 0 auto;width: 0;}
    span{
        position: absolute;top: 0;left: 0;right: 0;bottom: 0;text-align: center;padding: 10px 30px 20px;opacity: 0;@include transition(.2s all ease-out);z-index: 2;
        img{display: inline-block;vertical-align: middle;}
    }

    &:hover{
        /*&:before{height:100%;}*/
        /*span{opacity: 1;}*/
    }
}
.box-map{
    padding: 20px;background: #fafafa;position: relative;
    &:before{content:"";position: absolute;top: 25px;right: 25px;width: 30px;height: 30px;background: url('../images/material/ic_search_blue.png')no-repeat center;z-index: 10;}
    p{font-size: 14px;vertical-align: middle;}
    .map-img{
        position: relative;cursor: pointer;overflow: hidden;
        /*&:before{content:"";position: absolute;top: 0;left: 0;bottom: 0;right: 0;background: rgba(0,0,0,.5);z-index: 2;opacity: 0;@include transition(0.2s all ease-out);}*/
        img{width:100%;display: block;margin-bottom: 10px;cursor: pointer;@include transition(0.2s all ease-out);}
        &:hover{
            img{@include transform(scale(1.05));}
            /*&:before{opacity: 1;}*/
        }
    }
    .icwp{margin-right: 5px;}
}
.trash{
    display: inline-block;width: 45px;height: 45px;background:url('../images/material/ic_trash.png')no-repeat center;
    &:hover{opacity:.5;}
}
.box-clonning{
    .clonning{
        &.incloning{display: none;}
    }
}


/* footer
----------------------------------------------------------------------------------------------*/

footer{
    padding: 50px 0 40px; background: #f3f4f8; border-top: 1px solid #e73726; position: relative; margin-top: 60px;
    .wrapper{ width: 1170px; }
    &:after{
        content:''; width: 100%; height: 10px; background: #1c2674; position: absolute; bottom: 0; left: 0; right: 0;
    }
    .left{
        width: 48.717%;
        &:after{ content:''; clear: both; display: block; }
        .logo{
            float: left; width: 38.596%; margin-right: 14.035%;
        }
        .box-addrs{ 
            float: left; width:47.368%;
            .tittle-fot{display: block; font-size:14px; font-weight: bold; color: #1c2674; margin-bottom: 10px; }
            .addrs{ font-size: 13px; font-weight:600; color: #333; margin-bottom: 5px; display: block; }
            p{ font-size: 13px; color: #333; font-weight: 500; margin-bottom: 5px; line-height: 16px;}
            a{ display: inline-block; color: #333; font-size: 13px; font-weight: 500;  }
        }
    }
    .right{
        width: 31.623%; text-align: right;
        .row{
            display: block; margin-bottom: 15px;
            &:after{content:''; display: block; clear: both;}
           .col{
                float: right;
                &:last-child{ margin-right:20px; }
                a{ 
                    font-size: 13px; color: #333; font-weight: bold; position: relative; 
                    &.cont{
                         margin-right: 5px; padding-right: 10px;
                        &:after{ content:'|'; position: absolute; right: 0; top: 0; bottom: 0;  font-size: 16px;}
                    }
                }
                .box-medsos{
                    a{ margin:0 5px;  }
                }
           }
        }
        .cpy{
            p{ font-size: 13px; color: #333; font-weight: 500; }
            a{ font-size: 13px; color: #333; font-weight: 500; }
        }
    }

    &.footer-fixed{
        position: fixed;bottom: 0;left: 0;right: 0;
    }
}

/*footer{
    border-top: 3px solid $red;
    .logo-foot{margin-bottom: 35px;display: block;}
    .foot-top{
        background: #080d35;color: #b4b9e2;padding: 90px 0 120px;
        .left{
            width: 285px;
            h6{font-size: 16px;margin-bottom: 5px;}
            p{font-size: 14px;line-height: 18px;margin-bottom: 40px;}
            a{color: #b4b9e2;}
        }
        .right{width: 1060px;}
    }
    .foot-bottom{
        text-align: center;padding: 20px 0;font-size: 14px;color: #666666;
        a{color: #666666;}
    }
}
.nav-foot{
    margin: 0 -20px;
    @include afterboth;
    .ls-nav-foot{
        float: left;width: 20%;@include boxSizing(border-box);padding: 0 20px;
        @include boxSizing(border-box);margin: 0 45px;display: inline-block;vertical-align: top;
        h6{font-size: 16px;color: $red;text-transform: uppercase;margin-bottom: 15px;}
        ul{
            li{
                margin-bottom: 15px;
                a{
                    font-size: 14px;color: #b4b9e2;@include transition(.2s all ease-out);
                    &:hover{color:$orange;}
                }
            }
        }

        &:first-child{margin-left: 0;}
        &:last-child{margin-right: 0;}
    }
}
.box-sosmed{
    a{
        display: inline-block;margin-right: 20px;
        &:last-child{margin-right:0;}
    }
}*/
